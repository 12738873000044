import React, { useState, useEffect } from 'react'

import { useAuth } from '../contexts/authContext/index.js';
import { useApi } from '../config';
import { Link } from 'react-router-dom';
import "../css/calendartab.css"
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import "../css/modal.css"
import Select from 'react-select'
import Switch from 'react-js-switch';
import { CgCommunity } from "react-icons/cg";
import { IoIosRadioButtonOff } from "react-icons/io";
import { IoIosRadioButtonOn } from "react-icons/io";
import { HiMiniUserGroup } from "react-icons/hi2";
import { MdGroupAdd } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";
import { BiSolidMessageAltDetail } from "react-icons/bi";
import { IoCalendarNumber } from "react-icons/io5";
import { IoIosCreate } from "react-icons/io";
import { TbCalendarPlus } from "react-icons/tb";
import EventSetup from './Modals/EventSetup';
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
import "../css/inviteModal.css"
import { TagsInput } from "react-tag-input-component"
const Priority = (props) => {
    const api = useApi();
    const { user } = useAuth();
    const [priority, setPriority] = useState("")

    const handleChangePriority = (p, id) => {
        setPriority(p)
        api.api.post('/api/changePriority', { email: user.email, id: id, priority: p })
    }
    return (

        <div>
            <input value={priority} onChange={(e) => handleChangePriority(e.target.value, props.i.categories_id)} type='number' max={10} min={1}></input>
            {props.i.name}
        </div>
    )
}
export default function CalendarTab(props) {
    const [contactVis, setContactVis] = useState(false)
    const [usersModal, setUsersModal] = useState(false)
    const [circleName, setCircleName] = useState("")
    const api = useApi();
    const { user } = useAuth();
    const [tags, setTags] = useState([])
    const [events, setEvents] = useState([])
    const [selectedCats, setSelectedCats] = useState([])
    const [selectedCircles, setSelectedCircles] = useState([])
    const [cat, setCat] = useState("")
    const [calName, setCalName] = useState("")
    const [userCats, setUserCats] = useState([])
    const [inter, setInter] = useState("");
    const [intra, setIntra] = useState("");
    const [options, setOptions] = useState([])
    const [hidden, setHidden] = useState(false)
    const [circled, setCircled] = useState(false)
    const [cal, setCal] = useState({})
    const [circlesModal, setCirclesModal] = useState(false)
    const [contactOnly, setContactOnly] = useState(false)
    const [milieuCals, setMilieuCals] = useState([])
    const [homeCals, setHomeCals] = useState([])
    const [milieus, setMilieus] = useState([])
    const [priority, setPriority] = useState("")
    const [toggleModal, setToggleModal] = useState(false)
    const [toggle, setToggle] = useState(false)
    const [defaultModal, setDefaultModal] = useState(false)
    const [sustain, setsustain] = useState(0)
    const [googleCals, setGoogleCals] = useState([])
    const [calData, setCalData] = useState({})
    const [settingsModal, setSettingsModal] = useState(false);
    const [checker, setchecker] = useState(0)
    const [modal, setModal] = useState(false)
    const [priorityModal, setPriorityModal] = useState(false)
    const [req, setReq] = useState(false);
    const [settingsPriv, setSettingsPriv] = useState(false);
    const [status, setStatus] = useState({})
    const [members, setMembers] = useState([])
    const [circles, setCircles] = useState([])
    const [check, setCheck] = useState(0)
    const [contactStatus, setContactStatus] = useState(false)
    const [circleContactModal, setCircleContactModal] = useState(false)
    const [selectedContactCircle, setSelectedContactCircle] = useState([])
    const [milieuSettingsModal, setMilieuSettingsModal] = useState(false)
    const [googlePub, setGooglePub] = useState([])
    const [mCals, setMCals] = useState([])
    const [priv, setPriv] = useState(false)
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [newCals, setNewCals] = useState([])
    const [subscribed, setSubscribed] = useState(false);
    const [subs, setSubs] = useState([])
    const [subTags, setSubTags] = useState([])
    const [apparentData, setApparentData] = useState([])
    const [apparentGoogleCals, setApparentGoogleCals] = useState([])
    const [circleStatus, setCircleStatus] = useState({})
    const [milieuSubs, setMilieuSubs] = useState([])
    const [selectedContacted, setSelectedContacted] = useState([])
    const [contacts, setContacts] = useState([])
    const [inviteModal, setInviteModal] = useState(false)
    const [selectedUncontacted, setSelectedUncontacted] = useState([])
    useEffect(() => {
        if (props.mode == "home") {
            //api.api.get(`/api/getSubTags/?email=${user.email}`)
            //    .then(res => setSubTags(res.data.map(i => { return { ...i, displayed: true } })))
            api.api.get(`/api/getSubs/?email=${user.email}`)
                .then(res => setSubs(res.data))
            api.api.get(`/api/getMilieuSubs/?email=${user.email}`)
                .then(res => setMilieuSubs(res.data))
            //api.api.get(`/api/googleCals?email=${user.email}`)
            //    .then((res) =>
            //        res.data.length > 0 ? setGoogleCals(res.data) : null)
            api.api.get(`/api/getCirclesByUser/?email=${user.email}`)
                .then((res) => setCircles(res.data))
            //api.api.get(`/api/getTagsByUser/?email=${user.email}`)
            //    .then((res) => setTags(res.data))
            api.api.get(`/api/getMilieuByUser/?email=${user.email}`)
                .then((res) => setMilieus(res.data))
            api.api.get(`/api/getAllCalendarsByUser/?email=${user.email}`)
                .then((res) => {
                    //setMilieuCals(res.data.milieu)
                    setHomeCals(res.data.home)
                })
            api.api.get(`/api/getDisplayedMilieuCals/?email=${user.email}`)
                .then((res) => {

                    setMilieuCals(res.data)
                })
            api.api.get(`/api/getAllCategories`)
                .then((res) => {
                    setOptions(res.data.map(i => {
                        return {
                            value: i.id,
                            label: i.name
                        }
                    }))
                })
            api.api.get(`/api/getUserCategories/?email=${user.email}`)
                .then((res) => { return setUserCats(res.data) })
        } else if (props.mode == "milieu") {
            api.api.get(`/api/getContactsByUser?email=${user.email}`)
                .then(res => setContacts(res.data.map(i => i.users_email == user.email ? { label: i.users_email1, value: i.users_email1 } : { label: i.users_email, value: i.users_email })))
            api.api.get(`/api/getReqStatus/?id=${props.id}`)
                .then((res) => setReq(!res.data[0].autojoin))

            api.api.get(`/api/getMilieuPrivacySettings/?id=${props.id}`)
                .then((res) => setSettingsPriv(res.data[0].public))


            api.api.get(`/api/getMemberStatus/?email=${user.email}&id=${props.id}`)
                .then(
                    (res) => {
                        console.log("STATUS", res.data)
                        res.data ? setStatus({ member: 1, admin: res.data[0].status }) : setStatus({ member: 0, admin: 0 })
                        res.data ? props.setmemberstatus({ member: 1, admin: res.data[0].status }) : props.setmemberstatus({ member: 0, admin: 0 })
                    }
                )
            api.api.get(`/api/getAllApparentCalendarsByMilieuId/?id=${props.id}&email=${user.email}`)
                .then((res) => {
                    setMilieuCals(res.data)
                })
        } else if (props.mode == "user") {
            //api.api.get(`/api/getSubTags/?email=${props.id}`)
            //    .then(res => setSubTags(res.data.map(i => { return { ...i, displayed: true } })))
            api.api.get(`/api/getApparentSubs/?user=${props.id}&email=${user.email}`)
                .then(res => {
                    setNewCals([])
                    setSubs([false, ...res.data])
                })
            //api.api.get(`/api/getUserGoogleCals/?user=${props.id}`)
            //    .then((res) => setGoogleCals(res.data))
            api.api.get(`/api/getUserCircles/?circler=${user.email}&circled=${props.id}`)
                .then((res) => setSelectedContactCircle(res.data.map(i => { return { label: i.name, value: i.circle_id } })))
            api.api.get(`/api/getCirclesByUser/?email=${user.email}`)
                .then((res) => setCircles(res.data))
            api.api.get(`/api/getCircleStatus/?email=${user.email}&user=${props.id}`)
                .then(res => setCircleStatus(res.data))
            api.api.get(`/api/getContactStatus/?email1=${props.id}&email2=${user.email}`)
                .then((res) => setContactStatus(res.data))
            //api.api.get(`/api/getTagsByUser/?email=${props.id}`)
            //    .then((res) => {
            //        props.setUserTags(res.data.map(i => { return { ...i, displayed: true } }))
            //        setTags(res.data.map(i => { return { ...i, displayed: true } }))
            //    })
            api.api.get(`/api/getMilieuByUser/?email=${props.id}`)
                .then((res) => setMilieus(res.data.map(i => { return { displayed: true, id: i.id, name: i.name } })))
            api.api.get(`/api/getAllApparentCalendarsByUser/?user=${props.id}&email=${user.email}`)
                .then((res) => {

                    props.setMCals(res.data.milieu.map(i => { return { cid: i.id, cname: i.name, mid: i.mid, mname: i.mname, displayed: true } }))
                    setMilieuCals(res.data.milieu.map(i => { return { cid: i.id, cname: i.name, mid: i.mid, mname: i.mname, displayed: true } }))
                    setHomeCals(res.data.home.map(i => { return { ...i, displayed: true } }))
                    props.setHCals(res.data.home.map(i => { return { ...i, displayed: true } }))
                })
            api.api.get(`/api/getAllCategories`)
                .then((res) => {
                    setOptions(res.data.map(i => {
                        return {
                            value: i.id,
                            label: i.name
                        }
                    }))
                })
            api.api.get(`/api/getUserCategories/?email=${props.id}`)
                .then((res) => { return setUserCats(res.data) })

        }

        api.api.get(`/api/getMilieuMembers/?id=${props.id}`)
            .then((res) => setMembers(res.data))

    }, [])
    const handleMilieuInvite = () => {



        setInviteModal(true)
    }
    const handleConfirmInvite = () => {
        const invitees = [...selectedUncontacted, ...selectedContacted.map(i => i.value)]
        api.api.post('/api/mInvite', { invitees: invitees, id: props.id, email: user.email })

        api.api.post('/api/milieuInvite', { email: user.email, invitees: invitees, id: props.id })
            .then(res => {
                console.log("MMNAMME",props.mname)
                for (var i in res.data.userdata) {
                    fetch("/.netlify/functions/invite", {
                        method: "POST",
                        body: JSON.stringify({
                            participation: res.data.participation,
                            invited: res.data.userdata[i].mode == "milieusignup" ? res.data.userdata[i].user.slice(1, res.data.userdata[i].user.length - 1) : JSON.parse(res.data.userdata[i].user).email,
                            invited_fName: res.data.userdata[i].mode == "milieusignup" ? null : JSON.parse(res.data.userdata[i].user).first_name,
                            invited_lName: res.data.userdata[i].mode == "milieusignup" ? null : JSON.parse(res.data.userdata[i].user).last_name,
                            mode: res.data.userdata[i].mode,
                            inviter: user.email,
                            mname: props.mname
                        })
                    }).then(res => setInviteModal(false)).catch(e => console.log(e))
                }
            }
            )

    }
    useEffect(() => {
        if (subs[0] == false) {
            setNewCals([])
            for (var i in subs.filter(j => j !== false)) {
                api.api.post(`/api/getApparentData/`, { datum: subs.filter(j => j !== false)[i], email: user.email })
                    .then((res) => {
                        if (res.data !== "private" && Object.keys(res.data).length > 0) {
                            console.log("ADDED", res.data)
                            setNewCals(newCals => [...newCals, { ...res.data, displayed: true }])
                            if (i == subs.filter(j => j !== false).length - 1) {
                                setCheck(1)
                            }
                        }
                    }
                    )
            }
        }

    }, [subs])
    useEffect(() => {
        if (check == 1) {
            props.setUserSubs(newCals)
            setSubs(newCals)
            setCheck(0)
        }

    }, [check])
    useEffect(() => {
        console.log(milieuCals)
    }, [milieuCals])
    useEffect(() => {
        var app = []
        setGooglePub([])
        console.log("GOG", googleCals)
        console.log("CIRCLE", circleStatus)
        console.log("STATUS", contactStatus)
        for (var i in googleCals) {

            if (googleCals[i].circled) {
                if (circleStatus.map(j => j.circle_id).some(j => googleCals.filter(k => k.gid == googleCals[i].gid).map(k => k.circled_id).includes(j))) {
                    app.push(googleCals[i])
                }
            } else if (googleCals[i].contacted) {

            } else if (googleCals[i].public) {

            }

        }
    }, [googleCals])

    useEffect(() => {
        setCalName(props.cal.name)
        if (props.mode == "home") {
            api.api.get(`/api/getSubStatus/?email=${user.email}&calid=${props.cal.id}`)
                .then(res => {
                    setSubscribed(res.data)
                })
        } else if (props.mode == "milieu") {
            api.api.get(`/api/getMilieuSubStatus/?email=${user.email}&calid=${props.cal.id}`)
                .then(res => {
                    setSubscribed(res.data)
                })

        }
        if (props.cal.sub) {





            api.api.get(`/api/getCalCircles/?calid=${props.cal.id}&isGoogle=${props.cal.isHome == "google" ? 1 : 0}&sub=${true}`)
                .then((res) => {

                    setSelectedCircles(res.data.map(i => { return { label: i.name, value: i.id } }))
                })


        } else {
            api.api.get(`/api/getCalCircles/?calid=${props.cal.id}&isGoogle=${props.cal.isHome == "google" ? 1 : 0}`)
                .then((res) => {

                    setSelectedCircles(res.data.map(i => { return { label: i.name, value: i.id } }))
                })
        }
    }, [props.cal])

    useEffect(() => {

        console.log("CALPUB", props.calPub)
        setContactVis(props.calPub.contact)
        setCircled(props.calPub.circle)
        if (!props.calPub.circle && !props.calPub.contact) {

            props.calPub.public ?
                setHidden(1) :
                setHidden(0)
        }
    }, [props.calPub])
    const handlePriority = () => {
        setPriorityModal(!priorityModal)
    }

    //////subscription
    const handleSubscribe = () => {


        if (props.mode == "user") {
            if (subscribed) {
                api.api.post('/api/unsubscribeToUserCal', { calid: props.cal.id, email: user.email })
            }
            else {
                if (props.cal.isGoogle) {
                    api.api.post('/api/subscribeToUserGoogleCal', { calid: props.cal.id, email: user.email })
                } else {
                    api.api.post('/api/subscribeToUserCal', { calid: props.cal.id, email: user.email })
                }
            }
        } else if (props.mode == "milieu") {

            if (subscribed) {
                api.api.post('/api/unsubscribeToMilieuCal', { calid: props.cal.id, email: user.email })
            }
            else {

                api.api.post('/api/subscribeToMilieuCal', { calid: props.cal.id, email: user.email })

            }


        }
    }

    const handleHideSubTag = (id) => {


        var tag = subTags.filter(i => i.tags_id == id)[0]
        setSubTags(subTags => [...subTags.filter(i => i.tags_id !== id), { ...tag, displayed: tag.displayed ? false : true }])

    }
    useEffect(() => {
        if (props.mode == "home") {
            api.api.get(`/api/getEventStatus?tags=${subTags.filter(i => i.displayed).map(i => i.tags_id).filter((v, i, a) => a.indexOf(v) == i).join(',')}`).
                then(res => {
                    props.setDisplayedSubEvents(res.data)
                })
        } else if (props.mode == "user") {
            api.api.get(`/api/getEventStatus?tags=${subTags.filter(i => i.displayed).map(i => i.tags_id).filter((v, i, a) => a.indexOf(v) == i).join(',')}`).
                then(res => {
                    props.setDisplayedUserTags(res.data)
                })



        }
    }, [subTags])
    useEffect(() => {
        props.reset()
        console.log("SUBEVENTS", props.displayedSubEvents)
    }, [props.displayedSubEvents])
    const handleCircling = () => {

        if (props.cal.sub) {
            api.api.post('/api/clearCalCircles', { sub: true, id: props.cal.id, isGoogle: props.cal.google })
            for (var i = 0; i < selectedCircles.length; i++) {
                api.api.post('/api/circleCalendar', { email: user.email, id: selectedCircles[i].value, calid: props.cal.id, isGoogle: props.cal.google })
            }
        } else {
            api.api.post('/api/clearCalCircles', { sub: false, id: props.cal.id, isGoogle: props.cal.isHome == "google" })
            for (var i = 0; i < selectedCircles.length; i++) {
                api.api.post('/api/circleCalendar', { email: user.email, id: selectedCircles[i].value, calid: props.cal.id, isGoogle: props.cal.isHome == "google" })
            }
        }
    }
    const handleContactVis = () => {
        if (settingsModal) {
            setsustain(sustain + 1)
            if (!contactVis) {
                setCircled(false)
            }
            setContactVis(!contactVis)
        }
    }
    const handleCloseMilieuSettings = () => {
        setsustain(sustain + 1)
        setMilieuSettingsModal(!milieuSettingsModal)
        api.api.get(`/api/getReqStatus/?id=${props.id}`)
            .then((res) => setReq(!res.data[0].autojoin))
        api.api.get(`/api/getMilieuPrivacySettings/?id=${props.id}`)
            .then((res) => setSettingsPriv(res.data[0].public))
    }

    useEffect(() => {
        if (settingsModal && props.mode == "milieu") {

            api.api.post('/api/changeMilieuCalendarName', { name: calName, id: props.cal.id })
                .then((res) => {
                    api.api.get(`/api/getAllCalendarsByMilieuId/?id=${props.id}`)
                        .then((res) => {
                            //props.reset()
                            props.setCal({ name: calName, isHome: false, id: props.cal.id, isMilieu: true })
                            setMilieuCals(res.data)
                        })

                })

        }
        else if (settingsModal && props.mode == "home") {
            api.api.post('/api/changeCalendarName', { mode: props.mode, name: calName, id: props.cal.id, isHome: props.cal.isHome })
                .then((res) => {
                    api.api.get(`/api/getAllCalendarsByUser/?email=${user.email}`)
                        .then((res) => {
                            //props.reset()
                            setMilieuCals(res.data.milieu)
                            props.setCal({ name: calName, isHome: true, id: props.cal.id })
                            setHomeCals(res.data.home)
                        })

                })
        }
    }, [calName])

    const handleMilieuSettings = () => {
        setsustain(sustain + 1)
        setMilieuSettingsModal(!milieuSettingsModal)
    }
    const makeAdmin = (email) => {
        api.api.post('/api/makeAdmin', { email: email, id: props.id })

    }
    const handleApplyMilieuSettings = () => {
        api.api.post('/api/setMilieuPrivacy', { id: props.id, privacy: settingsPriv })
        api.api.post('/api/setMilieuJoin', { id: props.id, autojoin: !req })
    }
    const handleCircled = () => {
        if (settingsModal) {
            setsustain(sustain + 1)
            setCircled(!circled)
        }
    }
    const handleHidden = () => {
        if (settingsModal) {
            setsustain(sustain + 1)


            if (!hidden) {
                setContactVis(false)
            }
            setHidden(!hidden)
        }

    }
    useEffect(() => {

        if (settingsModal) {
            console.log(props.mode, contactVis, hidden, circled)
            api.api.post('/api/contactVis', { mode: props.mode, email: user.email, sub: props.cal.sub, id: props.cal.id, status: contactVis, isGoogle: props.cal.isHome == "google" })
                .then(res => console.log(res))
            api.api.post('/api/hideCalendar', { mode: props.mode, email: user.email, sub: props.cal.sub, id: props.cal.id, status: hidden, isGoogle: props.cal.isHome == "google" })
            api.api.post('/api/setCircleCalendar', { mode: props.mode, email: user.email, sub: props.cal.sub, id: props.cal.id, status: circled, isGoogle: props.cal.isHome == "google" })
        }
    }, [hidden, circled, contactVis])

    const handleUserCircling = () => {
        api.api.post('/api/clearUserCircles', { circler: user.email, circled: props.id })
        for (var i = 0; i < selectedContactCircle.length; i++) {
            api.api.post('/api/circleUser', { circler: user.email, circled: props.id, id: selectedContactCircle[i].value })
        }
    }

    const handleAddContact = () => {
        api.api.post('/api/requestContact', { email: user.email, subject: props.id })

    }

    const handleRemoveContact = () => {
        api.api.post('/api/removeContact', { email: user.email, subject: props.id })
    }


    const handleMembership = () => {

        if (status.member == 1) {
            api.api.post('/api/leave', { email: user.email, id: props.id })
        } else {
            if (req) {
                api.api.post('/api/reqMember', { email: user.email, id: props.id })
            } else {
                api.api.post('/api/joinMilieu', { email: user.email, id: props.id })
            }
        }
    }
    useEffect(() => {

        if (sustain == checker) { return } else {
            setToggle(true); setchecker(sustain)
        }
    }, [toggle, sustain, checker])

    const handleCalendarCreate = () => {
        setsustain(sustain + 1)
        if (props.mode == "home") {
            api.api.post('/api/createUserCalendar/', { email: user.email })
                .then((res) => console.log(res.data))
        } else if (props.mode == "milieu") {
            api.api.post(`api/createMilieuCalendar`, { id: props.id })
        }

    }
    const handleCircleContact = () => {
        setsustain(sustain + 1)
        setCircleContactModal(true)
    }
    const handleCreateCircle = () => {
        api.api.post(`/api/createCircle/`, { email: user.email, name: circleName })
    }
    const handleCircles = () => {
        setsustain(sustain + 1)
        setCirclesModal(true);
    }
    const handleCreateTag = () => {
        setsustain(sustain + 1)
        setModal(true);
    }
    const handleChangeCalName = (name) => {
        api.api.post('/api/changeCalendarName', { name: calName, id: props.cal.id, isHome: props.cal.isHome })
            .then((res) => {
                api.api.get(`/api/getAllCalendarsByUser/?email=${user.email}`)
                    .then((res) => {
                        //props.reset()
                        setMilieuCals(res.data.milieu)
                        setHomeCals(res.data.home)
                    })

            })


    }

    const handleSetMCalDisplay = (name, calid) => {
        if (props.mode == "home") {
            console.log(calid)
            api.api.post(`/api/setMCalDisplay`, { user: user.email, calid: calid })
                .then((res) => props.reset()
                )
        }
        if (props.mode == "milieu") {
            props.setMilieuCals([...props.milieuCals.filter(i => calid !== i.id), {
                name: name, isHome: false, id: calid, displayed:
                    props.milieuCals.filter(i => calid == i.id)[0].displayed == true ? false : true
            }])
        }
        if (props.mode == "user") {

            if (props.mCals.length > 0) {
                setMilieuCals(milieuCals => [...milieuCals.filter(i => calid !== i.cid), {
                    ...milieuCals.filter(i => calid == i.cid)[0],
                    displayed: milieuCals.filter(i => calid == i.cid)[0].displayed ? false : true
                }].sort((a, b) => {
                    if (a.cid < b.cid) {
                        return -1;
                    }
                    if (a.cid > b.cid) {
                        return 1;
                    }
                    return 0;
                }))
                props.setMCals([...props.mCals.filter(i => calid !== i.cid), {
                    ...props.mCals.filter(i => calid == i.cid)[0],
                    displayed: props.mCals.filter(i => calid == i.cid)[0].displayed ? false : true
                }].sort((a, b) => {
                    if (a.cid < b.cid) {
                        return -1;
                    }
                    if (a.cid > b.cid) {
                        return 1;
                    }
                    return 0;
                }))

            }
        }

    }
    const handleSetSubDisplay = (name, id, isGoogle) => {
        if (props.mode == "home") {
            if (isGoogle) {
                props.googleSubs.includes(id) ? props.setGoogleSubs([...props.googleSubs.filter(i => i !== id)])
                    : props.setGoogleSubs([...props.googleSubs, id])
            } else {
                api.api.post('/api/setSubDisplay', { calid: id, email: user.email })
                    .then((res) => props.reset())
            }
        }
        else if (props.mode == "user") {
            if (isGoogle) {
                if (props.userGoogleSubs.includes(id)) {
                    props.setUserGoogleSubs([...props.userGoogleSubs.filter(i => i !== id)])
                } else {
                    props.setUserGoogleSubs([...props.userGoogleSubs, id])
                }
            } {
                var targ = subs.filter(i => i.cid == id)[0]
                var targ2 = props.userSubs.filter(i => i.cid == id)[0]
                setSubs(subs => [...subs.filter(i => i.cid !== id), { ...targ, displayed: targ.displayed ? false : true }])
                props.setUserSubs([...props.userSubs.filter(i => i.cid !== id), { ...targ2, displayed: targ2.displayed ? false : true }])
            }
        }
    }
    const handleSettings = () => {
        setsustain(sustain + 1)
        setSettingsModal(!settingsModal)

    }
    const handleDefault = () => {
        setsustain(sustain + 1)
        setDefaultModal(true)
    }
    const handleDeleteCalendar = () => {

        api.api.post(`/api/deleteCalendar`, { cal: props.cal })
            .then((res) => {
                api.api.get(`/api/getAllCalendarsByUser/?email=${user.email}`)
                    .then((res) => {
                        if (res.data.home.filter(i => i.def == 1).length < 1) {
                            props.setCal({ name: res.data.home.filter(i => i.def == 0)[0].name, isHome: true, id: res.data.home.filter(i => i.def == 0)[0].id })
                        } else {
                            props.setCal({ name: res.data.home.filter(i => i.def == 1)[0].name, isHome: true, id: res.data.home.filter(i => i.def == 1)[0].id })
                        }
                        props.setMCals(res.data.milieu.map(i => {
                            return {
                                ...i, displayed:
                                    props.mCals.filter(j => j.id == i.id)[0].displayed
                            }
                        }))
                        setMilieuCals(res.data.milieu.map(i => {
                            return {
                                ...i, displayed:
                                    milieuCals.filter(j => j.id == i.id)[0].displayed
                            }
                        }))
                        setHomeCals(res.data.home)
                    })

            })
    }
    const handleSetDefault = (id) => {
        api.api.post('/api/setDefault', { email: user.email, id: id })
    }

    const handleEventCreate = () => {
        setsustain(sustain + 1)
        setToggleModal(!toggleModal)

    }
    const createTag = () => {
        api.api.post('/api/createTag', { email: user.email, priv: intra, category: cat })
            .then(res => props.reset())

    }
    useEffect(() => {
        props.setUserGoogleCals(googleCals)
        props.setUserGoogleEvents([])
        console.log("GOOGLE CALS", googleCals)
    }, [googleCals])
    const handleDisplay = (id, isGoogle = 0) => {

        if (isGoogle) {
            if (props.mode == "home") {
                console.log(id)
                api.api.post('/api/setDisplay/', { calid: id, isGoogle: true }).then((res) => { props.addGoogleCals() })
            } else if (props.mode == "user") {
                var gCals = googleCals.filter(i => googlePub.includes(i.gid))
                setGoogleCals(googleCals => [...gCals.filter(i => id !== i.gid), {
                    ...gCals.filter(i => id == i.gid)[0],
                    displayed: gCals.filter(i => id == i.gid)[0].displayed ? false : true
                }].sort((a, b) => {
                    if (a.summary < b.summary) {
                        return -1;
                    }
                    if (a.summary > b.summary) {
                        return 1;
                    }
                    return 0;
                }))
            }



        }
        else {
            if (props.mode == "home") {
                api.api.post('/api/setDisplay/', { calid: id, isGoogle: false })
                    .then(res => props.reset())
            } else if (props.mode == "user") {

                if (props.hCals.length > 0) {
                    setHomeCals(homeCals => [...homeCals.filter(i => id !== i.id), {
                        ...homeCals.filter(i => id == i.id)[0],
                        displayed: homeCals.filter(i => id == i.id)[0].displayed ? false : true
                    }].sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    }))
                    props.setHCals([...props.hCals.filter(i => id !== i.id), {
                        ...props.hCals.filter(i => id == i.id)[0],
                        displayed: props.hCals.filter(i => id == i.id)[0].displayed ? false : true
                    }].sort((a, b) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    }))

                }
                //props.reset()
            }
        }
    }




    useEffect(() => {
        props.reset()
        console.log("REEEEE")
    }, [props.userTags])
    const handleHideTag = (id) => {
        setsustain(sustain + 1)
        if (props.mode == "home") {
            api.api.post('/api/setTagDisplay', { tagid: id })
                .then((res) => {
                    console.log("Complete")
                    props.reset()
                })


        } else {

            setTags(tags => [...tags.filter(i => i.tags_id !== id), {
                ...tags.filter(i => i.tags_id == id)[0],
                displayed: tags.filter(i => i.tags_id == id)[0].displayed ? false : true
            }])
            props.setUserTags([...props.userTags.filter(i => i.tags_id !== id), {
                ...props.userTags.filter(i => i.tags_id == id)[0],
                displayed: props.userTags.filter(i => i.tags_id == id)[0].displayed ? false : true
            }])
            props.reset()
        }
    }

    useEffect(() => {
        console.log("USER", props.userTags)
    }, [tags, props.userTags])

    const handleHide = (milieuId) => {

        setsustain(sustain + 1)
        if (props.mode == "home") {

            api.api.post('/api/setMilieuDisplay', { email: user.email, mId: milieuId })
                .then((res) => {
                    api.api.get(`/api/getDisplayedMilieuCals/?email=${user.email}`)
                        .then((resp) => {
                            setMilieuCals(resp.data)
                            props.reset()

                        })
                })



        } else if (props.mode == "user") {

            setMilieus(milieus => [...milieus.filter(i => i.id !== milieuId),
            { name: milieus.filter(i => i.id == milieuId)[0].name, id: milieuId, displayed: milieus.filter(i => i.id == milieuId)[0].displayed == true ? false : true }])
        }
    }


    const resetTab = () => {
        api.api.get(`/api/getDisplayedMilieuCals/?email=${user.email}`)
            .then((res) => {
                setMilieuCals(res.data)
                props.reset()

            })
    }
    useEffect(() => {
        console.log(props.cal)
    }, [props.cal])
    return (
        <div className={toggle ? "tab active" : "tab"} onClick={() => { setToggle(!toggle) }}>
            <div className="container">
                <div className='hub'>
                    <div className='containment'>
                        {props.mode == "home" || props.mode == "user" ?
                            <div className='home'>
                                <div>
                                    <h3 className='head'>Personal Calendars</h3>
                                </div>
                                <div className='scroll'>
                                    {homeCals ? homeCals.map(i =>
                                        <div style={{ display: 'flex', alignItems: "center" }}>
                                            {props.displayedCals.map(i => i.id).includes(i.id) || props.hCals.filter(i => i.displayed).map(i => i.id).includes(i.id) ?

                                                <IoIosRadioButtonOn onClick={() => { setsustain(sustain + 1); handleDisplay(i.id) }} style={{ opacity: 1, width: "10%", height: "10%", color: "E07A5F" }} />
                                                :
                                                <IoIosRadioButtonOff onClick={() => { setsustain(sustain + 1); handleDisplay(i.id) }} style={{ opacity: 1, width: "10%", height: "10%", color: "E07A5F" }} />

                                            }
                                            <div onClick={() => { setsustain(sustain + 1); props.setCal({ name: i.name, isHome: true, id: i.id }) }} className={props.cal.id == i.id && !props.cal.sub && !props.cal.isMilieu ? 'activehomeCal' : 'homeCal'} id={i.id}>
                                                {i.name ? i.name : "No title"}
                                            </div>
                                        </div>
                                    ) : <></>}
                                    {googleCals.length > 0 && props.mode == "home" ? null : <></>}
                                    {googleCals ? props.mode == "user" ? /* googleCals.filter(i => googlePub.includes(i.gid)).map(i =>
                                        <div className='homeCal' onClick={() => { setsustain(sustain + 1); handleDisplay(props.mode == "user" ? i.gid : i.id, 1); props.setCal({ name: i.summary ? i.summary : i.name, isHome: "google", id: props.mode == "user" ? i.gid : i.id, isGoogle: true }) }}>
                                            {i.name || i.summary}

                                        </div>
                                    ) */ null :
                                        /*  googleCals.map(i =>
                                             <div className='homeCal' onClick={() => { setsustain(sustain + 1); handleDisplay(props.mode == "user" ? i.gid : i.id, 1); props.setCal({ name: i.summary ? i.summary : i.name, isHome: "google", id: props.mode == "user" ? i.gid : i.id, isGoogle: true }) }}>
                                                 {i.name || i.summary}
 
                                             </div>
                                         ) */
                                        null : <></>}
                                    {subs.length > 0 && props.mode == "home" ? "Subscriptions" : <></>}
                                    {subs.length > 0 && props.mode == "home" ?
                                        Array.isArray(subs) ? subs.map(i => i.user).filter((v, i, a) => a.indexOf(v) == i)
                                            .map(i => <div>
                                                {
                                                    i
                                                }
                                                {subs.filter(j => i == j.user).map(j =>

                                                    <div style={{ display: 'flex', alignItems: "center" }}>
                                                        {props.displayedSubCals.map(i => i.usercalendars_id).includes(j.cid) ?

                                                            <IoIosRadioButtonOn onClick={() => { setsustain(sustain + 1); handleSetSubDisplay(j.cname, j.cid, j.gid ? true : false) }} style={{ opacity: 1, width: "10%", height: "10%", color: "E07A5F" }} />
                                                            :
                                                            <IoIosRadioButtonOff onClick={() => { setsustain(sustain + 1); handleSetSubDisplay(j.cname, j.cid, j.gid ? true : false) }} style={{ opacity: 1, width: "10%", height: "10%", color: "E07A5F" }} />

                                                        }
                                                        <div onClick={() => { setsustain(sustain + 1); props.setCal({ name: j.cname, isHome: false, id: j.cid, google: j.gid ? true : false, sub: true }) }} className={props.cal.id == j.cid && props.cal.sub ? 'activeMilieuCal' : 'milieuCal'} id={j.cid}>
                                                            {j.cname ? j.cname : "No Title"}
                                                        </div>
                                                    </div>)}




                                            </div>
                                            )

                                            : <></> : <></>}


                                </div>


                            </div> : null}
                        <div className='divider'></div>
                        <div className='milieu'>
                            <div>
                                <h3 className='head'>Communal Calendars</h3>
                            </div>
                            <div className='scroll'>


                                {props.mode == "home" || props.mode == "milieu" ?
                                    milieuCals.map(i => `${i.mid}-${i.mname}`).filter((v, i, a) => a.indexOf(v) == i)
                                        .map(i => <div>
                                            {
                                                i.split('-')[1]
                                            }
                                            {milieuCals.filter(j => i.split('-')[0] == `${j.mid}`).map(j =>
                                                <div style={{ display: 'flex', alignItems: "center" }} >
                                                    {props.mode == "home" ?
                                                        props.displayedMilieuCals.map(i => i.mcalendars_id).includes(j.cid) ?

                                                            <IoIosRadioButtonOn onClick={() => { setsustain(sustain + 1); handleSetMCalDisplay(j.cname, j.cid) }} style={{ opacity: 1, width: props.mode == "home" ? "10%" : "4%", height: props.mode == "home" ? "10%" : "4%", color: "E07A5F" }} />
                                                            :
                                                            <IoIosRadioButtonOff onClick={() => { setsustain(sustain + 1); handleSetMCalDisplay(j.cname, j.cid) }} style={{ opacity: 1, width: props.mode == "home" ? "10%" : "4%", height: props.mode == "home" ? "10%" : "4%", color: "E07A5F" }} />
                                                        :
                                                        props.milieuCals.filter(k => k.displayed).map(k => k.id).includes(j.cid) ?
                                                            <IoIosRadioButtonOn onClick={() => { setsustain(sustain + 1); handleSetMCalDisplay(j.cname, j.cid) }} style={{ opacity: 1, width: props.mode == "home" ? "10%" : "4%", height: props.mode == "home" ? "10%" : "4%", color: "E07A5F" }} />
                                                            :
                                                            <IoIosRadioButtonOff onClick={() => { setsustain(sustain + 1); handleSetMCalDisplay(j.cname, j.cid) }} style={{ opacity: 1, width: props.mode == "home" ? "10%" : "4%", height: props.mode == "home" ? "10%" : "4%", color: "E07A5F" }} />
                                                    }
                                                    <div onClick={() => { setsustain(sustain + 1); props.setCal({ name: j.cname, isHome: false, id: j.cid, isMilieu: true, sub: false }) }} className={props.cal.id == j.cid && props.cal.isMilieu ? 'activeMilieuCal' : 'milieuCal'} id={j.cid}>
                                                        {j.cname ? j.cname : "No Title"}
                                                    </div>
                                                </div>)}
                                        </div>
                                        )

                                    : null
                                }
                                {props.mode == "user" ?
                                    milieuCals.filter(i => milieus.filter(j => j.displayed).map(j => j.id).includes(i.mid)).map(i => `${i.mid}-${i.mname}`).filter((v, i, a) => a.indexOf(v) == i)
                                        .map(i =>
                                            <div>
                                                {
                                                    i.split('-')[1]
                                                }


                                                <div>
                                                    {milieuCals.filter(j => i.split('-')[0] == `${j.mid}`).map(j =>
                                                        <div style={{ display: 'flex' }}>

                                                            <div >
                                                                {
                                                                    props.mCals.filter(k => k.displayed).map(k => k.cid).includes(j.cid) ?

                                                                        <IoIosRadioButtonOn onClick={() => { setsustain(sustain + 1); handleSetMCalDisplay(j.cname, j.cid) }} style={{ opacity: 1, width: props.mode == "home" ? "100%" : "100%", height: props.mode == "home" ? "100%" : "100%", color: "E07A5F" }} />
                                                                        :
                                                                        <IoIosRadioButtonOff onClick={() => { setsustain(sustain + 1); handleSetMCalDisplay(j.cname, j.cid) }} style={{ opacity: 1, width: props.mode == "home" ? "100%" : "100%", height: props.mode == "home" ? "100%" : "100%", color: "E07A5F" }} />
                                                                }
                                                            </div>
                                                            <div onClick={() => { setsustain(sustain + 1); props.setCal({ name: j.cname, isHome: false, id: j.cid, isMilieu: true }) }} className={props.cal.id == j.cid && props.cal.isMilieu ? 'activeMilieuCal' : 'milieuCal'} id={j.cid}>
                                                                {j.cname ? j.cname : "No Title"}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                            </div>
                                        )

                                    : null
                                }

                            </div>
                        </div>
                    </div>


                </div>



                {props.mode !== "milieu" ?
                    <div className="accord">
                        <div>
                            <h3 style={{ color: "#F2CC8F" }}>Milieus</h3>
                        </div>
                        <div className='scrollingContainer'>
                            <div style={{ width: "100%" }}>



                                <div >
                                    {milieus.length > 0 ?
                                        milieus.map(i =>
                                            <div onClick={() => { handleHide(i.id) }} className='apparentMilieus' id={i.id}>
                                                {i.name}
                                            </div>) : null}



                                </div>
                            </div>

                            {/*  <div style={{ width: "100%" }}>
                                <div>
                                    <h3 style={{ color: "#F2CC8F" }}></h3>
                                </div >
                                <div className='scrolling'>
                                    {tags.map(i =>
                                        <div onClick={() => { handleHideTag(i.tags_id) }} className='apparentMilieus' id={i.id}>
                                            {`${i.prioritization} | ${i.name}`}
                                        </div>)}

                                    {props.mode=="home"?
                                    subTags.map(i => i.user).filter((v, i, a) => a.indexOf(v) == i).map(i =>

                                        <div>
                                            {i}
                                            {subTags.filter(j => j.user == i).map(j =>
                                                <div className='apparentMilieus' onClick={() => handleHideSubTag(j.tags_id)}>
                                                    {j.name}
                                                </div>
                                            )}
                                        </div>
                                    ):<></>}






                                </div>
                            </div> */}
                        </div>

                    </div>
                    : null}


                <div className="misc">
                    <div className='miscScroll'>
                        {props.mode == "milieu" ?
                            <div>
                                <div onClick={handleMembership} className='membership'>
                                    {status.member ? "Leave" : "Join"}
                                </div>
                                <div onClick={() => { setUsersModal(!usersModal) }} className='user'>
                                    Members
                                </div>
                            </div>
                            : null}
                        {props.mode == "milieu" && status.admin == 1 ?
                            <div>
                                <div onClick={handleMilieuInvite} className='mSettings'>
                                    Invite
                                </div>
                                <div onClick={handleMilieuSettings} className='mSettings'>
                                    Milieu Settings
                                </div>
                            </div>
                            : null}
                        {subscribed && props.mode !== "home" && !status.member ?
                            <div onClick={handleSubscribe} className='add'>
                                Unsubscribe
                            </div>
                            : null}
                        {!subscribed && props.mode !== "home" && !status.member ?
                            <div onClick={handleSubscribe} className='add'>
                                Subscribe
                            </div>
                            : null

                        }
                        {status.admin == 1 || props.mode == "home" ?
                            <div>
                                <div className='create'>
                                    {props.cal.isHome || props.cal.isMilieu ?
                                        <>
                                            <div onClick={handleCalendarCreate} className="ccalendar">
                                                <TbCalendarPlus style={{ opacity: 1, width: "100px", height: "100px", color: "E07A5F" }} />

                                            </div>
                                            <div onClick={handleEventCreate} className="event">
                                                <div>
                                                    <IoIosCreate style={{ opacity: 1, width: "100px", height: "100px", color: "E07A5F" }} /></div>

                                            </div>
                                        </>
                                        : null}
                                </div>
                                {props.cal.isMilieu || props.cal.sub ? null :
                                    <div onClick={handleSettings} className='settings'>
                                        Settings
                                    </div>}

                                {props.cal.isHome == true ?
                                    <div onClick={handleDefault} className='default'>
                                        Set Default Calendar
                                    </div>
                                    : null}
                                <div onClick={handleCircles} className="circles">
                                    Circles
                                </div>
                            </div>
                            : null}

                        {props.mode == "user" ?
                            <div>

                                {!contactStatus ?
                                    <div onClick={handleAddContact} className='add'>
                                        Add Contact
                                    </div> : <div onClick={handleRemoveContact} className='add'>
                                        Remove Contact
                                    </div>}
                                <div onClick={handleCircleContact} className='circle'>
                                    Circle Contact
                                </div >

                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <EventSetup reset={props.reset} setModal={handleEventCreate} cal={props.cal} modal={toggleModal} id={props.id} mode={props.mode} events={events} setEvents={setEvents} />
            </div>
            {modal && (
                <div className="modal">
                    <div className='overlay'>
                    </div>
                    <div className='modal-content'>

                        <div>

                            <Select
                                value={cat}
                                onChange={(e) => { setCat(e) }}
                                options={options}
                            />
                            <div>

                                <label>Prioritization</label>

                                <input type='number' min={1} max={10} value={intra} onChange={(e) => { setIntra(e.target.value) }} placeholder='Intra-prioritization'></input>


                            </div>
                        </div>
                        <button onClick={() => { setModal(!modal) }}>
                            Close
                        </button>
                        <button onClick={() => { createTag() }}>
                            Create Tag
                        </button>
                    </div>
                </div>
            )}
            {priorityModal && (
                <div className="modal">
                    <div className='overlay'>
                    </div>
                    <div className='modal-content'>
                        <h3>Priority</h3>
                        {userCats.map(i =>
                            <Priority i={i} />
                        )}

                        <button onClick={() => { setPriorityModal(!priorityModal) }}>
                            Close
                        </button>
                    </div>
                </div>
            )}

            <Modal style={{ padding: "400px" }} show={defaultModal} onHide={() => { setDefaultModal(!defaultModal) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Set Default Calendar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <ListGroup>
                            {props.mode == "home" ?

                                homeCals.map(i =>
                                    <ListGroup.Item onClick={() => { handleSetDefault(i.id) }}>
                                        {i.name ? `ID:${i.id}: ` + i.name : `ID:${i.id}: No Title`}
                                    </ListGroup.Item>
                                )
                                :
                                milieuCals.map(i =>
                                    <ListGroup.Item onClick={() => { handleSetDefault(i.id) }}>
                                        {i.name ? `ID:${i.id}: ` + i.name : `ID:${i.id}: No Title`}
                                    </ListGroup.Item>
                                )

                            }
                        </ListGroup>
                    </Card>

                </Modal.Body>
            </Modal>


            <Modal style={{ padding: "400px" }} show={settingsModal && props.mode == "milieu"} onHide={() => { setSettingsModal(!settingsModal) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Current Calendar Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body>
                            <ListGroup>
                                <ListGroup.Item>
                                    <label>Name</label>
                                    <input onChange={(e) => { setCalName(e.target.value) }} value={calName}></input>
                                </ListGroup.Item>
                                <div>
                                    <ListGroup.Item>
                                        <Form.Check // prettier-ignore
                                            type="switch"
                                            id="custom-switch"
                                            label="Public"
                                            checked={hidden} onChange={() => handleHidden()}
                                        />
                                    </ListGroup.Item>
                                    {!hidden ? <ListGroup.Item>
                                        <Form.Check // prettier-ignore
                                            type="switch"
                                            id="custom-switch"
                                            label="Visible to all Members"
                                            value={contactVis} onChange={() => handleContactVis()}
                                        />
                                    </ListGroup.Item> : null}

                                    {!hidden && !contactVis ? <ListGroup.Item>
                                        <Form.Check // prettier-ignore
                                            type="switch"
                                            id="custom-switch"
                                            label="Visible to Admins"
                                            value={circled} onChange={() => handleCircled()}
                                        />

                                    </ListGroup.Item>
                                        : null}
                                </div>
                            </ListGroup>
                        </Card.Body>
                        <Card.Footer>
                            <Button variant="danger" onClick={handleDeleteCalendar}>Delete Calendar</Button>
                        </Card.Footer>
                    </Card>

                </Modal.Body>
            </Modal>
            {settingsModal && props.mode == "milieu" && (
                <div className="modal">
                    <div className='overlay'>
                    </div>
                    <div className='modal-content'>
                        <h3>Current Calendar Settings</h3>

                        <div>
                            <label>Name</label>
                            <input onChange={(e) => { setCalName(e.target.value) }} value={calName}></input>
                        </div>


                        <div>
                            {props.googleCalPub ?
                                <div>
                                    <label>Public</label>
                                    <Switch value={hidden} onChange={() => handleHidden()} />
                                    {!hidden ? <div>
                                        <label>Visible to all Members</label>
                                        <Switch value={contactVis} onChange={() => handleContactVis()} />
                                    </div> : null}

                                    {!hidden && !contactVis ? <div>
                                        <label>Visible to Admins</label>
                                        <Switch value={circled} onChange={() => handleCircled()} />
                                    </div>
                                        : null}
                                </div>
                                : null}
                        </div>




                        <button onClick={handleDeleteCalendar}>Delete Calendar</button>


                        <button onClick={() => { setSettingsModal(!settingsModal) }}>
                            Close
                        </button>
                    </div>
                </div>
            )}



            <Modal style={{ padding: "400px" }} show={settingsModal && props.mode == "home"} onHide={() => { setSettingsModal(!settingsModal) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Current Calendar Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body>
                            <ListGroup>
                                {
                                    props.mode == "home" && !props.cal.sub ?
                                        <ListGroup.Item>
                                            <label>Name</label>
                                            <Form.Control onChange={(e) => { setCalName(e.target.value) }} value={calName} />
                                        </ListGroup.Item>
                                        : null}

                                <ListGroup.Item>
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="custom-switch"
                                        label="Public"
                                        checked={hidden} onChange={() => handleHidden()}
                                    />

                                </ListGroup.Item>
                                {!hidden ? <ListGroup.Item>
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="custom-switch"
                                        label="Visible to all Contacts"
                                        checked={contactVis} onChange={() => handleContactVis()}
                                    />
                                </ListGroup.Item> : null}

                                {!hidden && !contactVis ? <ListGroup.Item>
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="custom-switch"
                                        label="Circle Calendar"
                                        checked={circled} onChange={() => handleCircled()}
                                    />
                                </ListGroup.Item>
                                    : null}

                            </ListGroup>
                        </Card.Body>
                        <Card.Footer>
                            <Button variant="danger" onClick={handleDeleteCalendar}>Delete Calendar</Button>
                        </Card.Footer>
                    </Card>

                </Modal.Body>
            </Modal>
            {settingsModal && props.mode == "home" && (
                <div className="modal">
                    <div className='overlay'>
                    </div>
                    <div className='modal-content'>
                        <h3>Current Calendar Settings</h3>

                        {
                            props.mode == "home" && !props.cal.sub ?

                                <div>
                                    <label>Name</label>
                                    <input onChange={(e) => { setCalName(e.target.value) }} value={calName}></input>
                                </div>
                                : null
                        }
                        <div>
                            {props.googleCalPub ?
                                <div>
                                    <label>Public</label>
                                    <Switch value={hidden} onChange={() => handleHidden()} />
                                    {!hidden ? <div>
                                        <label>Visible to all Contacts</label>
                                        <Switch value={contactVis} onChange={() => handleContactVis()} />
                                    </div> : null}

                                    {!hidden && !contactVis ? <div>
                                        <label>Circle Calendar</label>
                                        <Switch value={circled} onChange={() => handleCircled()} />
                                    </div>
                                        : null}
                                </div>
                                : null}
                        </div>




                        <button onClick={handleDeleteCalendar}>Delete Calendar</button>


                        <button onClick={() => { setSettingsModal(!settingsModal) }}>
                            Close
                        </button>
                    </div>
                </div>
            )}


            <Modal style={{ padding: "400px" }} show={usersModal} onHide={() => { setUsersModal(!usersModal) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Members</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body>
                            <ListGroup>
                                <ListGroup.Item>
                                    <label>Admins</label>
                                    {members.filter(i => i.status == 1).map(i =>
                                        <ListGroup.Item onClick={() => { navigate(`/user/${i.users_email}`) }}>
                                            {i.users_email}
                                        </ListGroup.Item>
                                    )}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <label>Members</label>
                                    {members.filter(i => i.status == 0).map(i =>
                                        <ListGroup.Item>
                                            <div onClick={() => { navigate(`/user/${i.users_email}`) }}>
                                                {i.users_email}
                                            </div>
                                            {!i.status && status.admin ? <Button variant='primary' onClick={() => { makeAdmin(i.users_email) }}>Delegate</Button> : null}
                                        </ListGroup.Item>
                                    )}
                                </ListGroup.Item>

                            </ListGroup>
                        </Card.Body>
                    </Card>

                </Modal.Body>
            </Modal>
            {usersModal && (
                <div className="modal">
                    <div className='overlay'>
                    </div>
                    <div className='modal-content'>
                        <div>
                            <h2>Members</h2>
                        </div>

                        <div>
                            <div>
                                <h3>Admins</h3>
                                {members.filter(i => i.status == 1).map(i =>
                                    <div onClick={() => { navigate(`/user/${i.users_email}`) }}>
                                        {i.users_email}
                                    </div>
                                )}
                            </div>
                            <div>
                                <h3>Members</h3>
                                {members.filter(i => i.status == 0).map(i =>
                                    <div>
                                        <div onClick={() => { navigate(`/user/${i.users_email}`) }}>
                                            {i.users_email}
                                        </div>
                                        {!i.status && status.admin ? <button onClick={() => { makeAdmin(i.users_email) }}>Delegate</button> : null}
                                    </div>
                                )


                                }


                            </div>
                        </div>
                        <button onClick={() => { setUsersModal(!usersModal) }}>
                            Close
                        </button>
                    </div>
                </div>
            )}

            <Modal style={{ padding: "400px" }} show={circlesModal} onHide={() => { setCirclesModal(!circlesModal) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Circles</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body>
                            <ListGroup>
                                <ListGroup.Item>
                                    <label>Create a circle</label>
                                    <Form.Control value={circleName} onChange={(e) => { setCircleName(e.target.value) }} placeholder='Give your circle a name!'></Form.Control>
                                    <Button variant='primary' onClick={handleCreateCircle}>Create</Button>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <label>Associate this calendar to a circle</label>
                                    <Select options={circles.map(i => { return { label: i.name, value: i.id } })} isMulti={true} value={selectedCircles} onChange={setSelectedCircles} />

                                    <Button variant='primary' onClick={handleCircling}>Circle Calendar</Button>
                                </ListGroup.Item>

                            </ListGroup>
                        </Card.Body>
                    </Card>

                </Modal.Body>
            </Modal>

            <Modal style={{ padding: "400px" }} show={circleContactModal} onHide={() => { setCircleContactModal(!circleContactModal) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Circle Contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>

                        <Select value={selectedContactCircle} onChange={setSelectedContactCircle} isMulti={true} options={circles.map(i => { return { label: i.name, value: i.id } })} />
                        <Button variant='primary' onClick={handleUserCircling}>Circle User</Button>
                    </Card>

                </Modal.Body>
            </Modal>


            <Modal style={{ padding: "400px" }} show={milieuSettingsModal} onHide={handleCloseMilieuSettings}>
                <Modal.Header closeButton>
                    <Modal.Title>Milieu Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body>
                            <ListGroup>
                                <ListGroup.Item>


                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="custom-switch"
                                        label="Public"
                                        checked={settingsPriv} onChange={setSettingsPriv}
                                    />

                                </ListGroup.Item>
                                <ListGroup.Item>


                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="custom-switch"
                                        label="Join By Request"
                                        checked={req} onChange={setReq}
                                    />

                                </ListGroup.Item>

                            </ListGroup>
                        </Card.Body>
                        <Card.Footer>
                            <Button variant='primary' onClick={handleApplyMilieuSettings}>Apply</Button>

                        </Card.Footer>
                    </Card>

                </Modal.Body>
            </Modal>

            <Modal style={{ padding: "400px" }} show={inviteModal} onHide={() => setInviteModal(!inviteModal)}>
                <Modal.Header closeButton>
                    <Modal.Title>Invite</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <ListGroup>



                            <ListGroup.Item >
                                <label>Contacts</label>
                                <Select
                                    value={selectedContacted}
                                    onChange={(e) => { setSelectedContacted(e) }}
                                    options={contacts}
                                    isMulti={true}
                                />
                            </ListGroup.Item>

                            <ListGroup.Item >
                                <label>Uncontacted</label>
                                <TagsInput
                                    value={selectedUncontacted}
                                    onChange={setSelectedUncontacted}
                                    name="Emails"
                                    placeHolder="Enter an email"
                                    separators={" "}
                                />
                            </ListGroup.Item>



                        </ListGroup>
                        <Card.Footer>
                            <Button variant='primary' onClick={handleConfirmInvite}>Invite</Button>
                        </Card.Footer>
                    </Card>

                </Modal.Body>
            </Modal>


        </div>)
}