import Cal from '../components/Cal.js'
import NavBar from '../components/NavBar.js'
import axios from 'axios';
import React,{useEffect, useState} from 'react';
import { CgCommunity } from "react-icons/cg";
import { HiMiniUserGroup } from "react-icons/hi2";
import { MdGroupAdd } from "react-icons/md";
import { FaUserFriends } from "react-icons/fa";
import { BiSolidMessageAltDetail } from "react-icons/bi";
import { IoCalendarNumber } from "react-icons/io5";
import { IoIosCreate } from "react-icons/io";
import { TbCalendarPlus } from "react-icons/tb";
import "../css/home.css"
import { useApi } from '../config.js'
import { useParams } from 'react-router-dom';


import { useAuth0 } from '@auth0/auth0-react'
import { useAuth } from '../contexts/authContext/index.js';
import SideBarItem from '../components/SideBarItem.js';
import SideBar from '../components/SideBar.js'

export default function Home(props) {

    const api = useApi()
    const [toggle,setToggle] = useState(false)
    const { user, isAuthenticated ,isLoading } = useAuth();
    const [session,setSession] = useState("")
    useEffect(()=>{
        const queryParams = new URLSearchParams(window.location.search)
        const ses = queryParams.get("sessionId")
        setSession(ses)
    },[])
    
    useEffect(()=>{
        if(session){


    fetch("/.netlify/functions/stripe-pm", {
                method: "POST",
                body: JSON.stringify({
                    sessionId:session,
                })}).then(
                    async res=> {
                        const resp = await res.json()
                        api.api.post('/api/addStripeDetails',{email:user.email,customerId:resp.customer,paymentMethod:resp.paymentMethod})
                    }

                )
        }
    },[session])
    useEffect(()=>{
        console.log("AUTH",isAuthenticated,user)
        if(isAuthenticated){
            api.api.post('/api/signup',{email:user.email})
        }},[isLoading])
    if (isLoading) {
        return <div>Loading ...</div>;
    }


    const  handleSignIn = async ()=>{
        api.api.get('/getToken')
//        then((res)=>

//        api.api.get(`/getGoogleCalendars?token=${JSON.parse(res.data).access_token}`)
//        .then((res2)=>console.log(res2))
//    )

    }
    const getGoogleEventss = () => {
        api.api.post("/api/test")
    }

    return (

        <div className='whole'>
            {isAuthenticated && (
                <>
                    <NavBar/>


                    <div className='holder'>
                    <SideBar toggle={toggle} onClick={()=>{setToggle(!toggle)}} />

                    <div className='calendar'>
                        <Cal stripe={session} mode={'home'} />
                    </div>
                    </div>

                </>
            )}
        </div>
    )
}