
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import SignUp from '../components/Modals/SignUp'
import { useApi } from '../config';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import "../css/signin.css"
import { useAuth } from '../contexts/authContext';
import { doSignInWithEmailAndPassword, doCreateUserWithEmailAndPassword } from '../firebase/auth';
import { useAuth0 } from '@auth0/auth0-react'
import Form from 'react-bootstrap/Form'
export default function SignIn(props) {
    
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPass, setConfirmPass] = useState("")
    const [fName, setFName] = useState("")
    const [lName, setLName] = useState("")
    const [toggleSignUp, setToggleSignUp] = useState(false)
    const [toggleSignIn, setToggleSignIn] = useState(true)
    const [valid, setValid] = useState(true)
    const [passMatch, setPassMatch] = useState(true)
    const [isEmptyName, setIsEmptyName] = useState(false)
    const [isEmptyPass, setIsEmptyPass] = useState(false)
    const [isEmptyEmail, setIsEmptyEmail] = useState(false)
    const [validEmail, setValidEmail] = useState(true)
    //const {userLoggedIn} = useAuth()
    const { to } = useParams()
    const api = useApi()
    const { userLoggedIn } = useAuth()
    const handleSignIn = (e) => {
        e.preventDefault()
        if (toggleSignIn) {
            doSignInWithEmailAndPassword(email, password).then((res) => { navigate('/home') }).then(res => { api.api.post('/api/signup', { email: email, fName: fName, lName: lName }) })
                .catch(e => console.log("error"))
        } else {
            setValidEmail(true)
            setIsEmptyEmail(false)
            setIsEmptyPass(false)
            setIsEmptyName(false)
            setPassMatch(true)
            setToggleSignUp(false)
            setToggleSignIn(true)
        }

    }

    const handleSignUp = () => {
        if (toggleSignUp) {
            if (password == confirmPass) {
                setPassMatch(true)
            } else {
                setPassMatch(false)
            }
            if (fName.length > 0 && lName.length > 0) {
                setIsEmptyName(false)
            } else {
                setIsEmptyName(true)
            }
            if (password.length > 0) {
                setIsEmptyPass(false)
            } else {
                setIsEmptyPass(true)
            }
            if (email.length > 0) {
                setIsEmptyEmail(false)
            } else {
                setIsEmptyEmail(true)
            }
            if (email.includes("@")) {
                if (email.split("@")[1].includes(".") && email.split("@")[1].trim().length > 2 && email.split("@")[0].trim().length > 0) {
                    setValidEmail(true)
                } else {
                    setValidEmail(false)
                }
            } else {
                setValidEmail(false)
            }


            if (email.includes("@") ? email.split("@")[1].includes(".") && email.split("@")[1].trim().length > 2 && email.split("@")[0].trim().length > 0 && password == confirmPass && fName.length > 0 && lName.length > 0 && password.length > 0 && email.length > 0 : false) {
                doCreateUserWithEmailAndPassword(email, password).then(res => { api.api.post('/api/signup', { email: email, fName: fName, lName: lName }) }).then((res) => { navigate('/home') })
                    .catch(e => console.log("error"))
            }

        } else {
            setToggleSignUp(true)
            setToggleSignIn(false)
        }
    }
    return (

        <div className='page'>
            <h1>Milieu</h1>



            <div>
                <div className='name'>
                    <div className={toggleSignUp ? "active" : "inactive"}>
                        <Form.Control
                            placeholder='First Name'
                            value={fName}
                            onChange={(e) => setFName(e.target.value)}
                            className='formElement'
                        />
                    </div>
                    <div className={toggleSignUp ? "active" : "inactive"}>
                        <Form.Control
                            placeholder='Last Name'
                            value={lName}
                            onChange={(e) => setLName(e.target.value)}
                            className='formElement'
                        />
                    </div>

                </div>
                
                {!isEmptyName ? null :
                <div >
                        <Form.Text style={{flexDirection:"column"}} id="passwordHelpBlock" muted>
                            This is how your invitees will recognize you.
                        </Form.Text></div>}
                
                <div>
                    <Form.Control
                        placeholder='Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className='formElement'
                    />
                    {validEmail ? null :
                        <Form.Text id="passwordHelpBlock" muted>
                            Invalid Email.
                        </Form.Text>}
                    {!isEmptyEmail ? null :
                        <Form.Text id="passwordHelpBlock" muted>
                            Must provide Email.
                        </Form.Text>}
                </div>
                <div>
                    <Form.Control
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        className='formElement'
                        aria-describedby="passwordHelpBlock"
                    />
                </div>
                {!isEmptyPass ? null :
                        <Form.Text id="passwordHelpBlock" muted>
                            Must provide a password.
                        </Form.Text>}
                <div className={toggleSignUp ? "active" : "inactive"}>
                    <Form.Control
                        placeholder='Confirm Password'
                        value={confirmPass}
                        onChange={(e) => setConfirmPass(e.target.value)}
                        type="password"
                        className='formElement'
                        aria-describedby="passwordHelpBlock"
                    />
                    {passMatch ? null :
                        <Form.Text id="passwordHelpBlock" muted>
                            Both passwords must match.
                        </Form.Text>}

                </div>
            </div>
            {
                <Button className='button' onClick={handleSignIn} variant="primary">Sign In</Button>
            }
            <Button className='button' onClick={handleSignUp} variant="primary">Sign Up</Button>
        </div>
    )
}