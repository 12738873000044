import logo from './logo.svg';
import React, { useEffect, useState, } from "react";
import './App.css';
import { Link } from 'react-router-dom'
import NavBar from './components/NavBar.js'
import Home from './pages/Home.js'
//import 'bootstrap/dist/css/bootstrap.min.css';
import SignIn from './pages/SignIn.js'
import Cookies from 'universal-cookie'
import { jwtDecode } from 'jwt-decode'
import PrivateRoute from './pages/PrivateRoute.js';
//import AuthProvider from "./AuthProvider";
import ApiProvider from './config.js';
import { AuthProvider } from './contexts/authContext/index.js';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CreateMilieu from './pages/CreateMilieu.js';
import Search from './pages/Search.js';
import User from './pages/User.js'
import Milieu from './pages/Milieu.js';
import Contacts from './pages/Contacts.js'
import Messages from './pages/Messages.js';
import Milieus from './pages/Milieus.js';
import Blank from './pages/Blank.js'
import Settings from './pages/Settings.js';
import { useApi } from './config.js';
function App() {
  const api = useApi()



  return (
    <div className="App">
      <Router>
        <ApiProvider>
          <AuthProvider>
              <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="/to/:destination" element={<Blank />} />
                <Route path="/home" element={<Home />} />
                <Route path="/createMilieu" element={<CreateMilieu />} />
                <Route path="/search" element={<Search />} />
                <Route path="/milieu/:name/:id" element={<Milieu />} />
                <Route path="/user/:id" element={<User/>} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/notifications" element={<Messages />} />
                <Route path="/milieus" element={<Milieus />} />
                <Route path="/settings" element={<Settings/>}/>
                {/* Other routes */}
              </Routes>

          </AuthProvider>
        </ApiProvider>
      </Router>
    </div>
  );
}

export default App;
